import i18n from '@/i18n/i18n';

export const permissionTypes = [
	{ id: 1, name: i18n.t('permissionTypes.read') },
	{ id: 2, name: i18n.t('permissionTypes.write') },
	{ id: 3, name: i18n.t('permissionTypes.no-access') },
];

export const permissionType = {
	read: 1,
	write: 2,
	noAccess: 3
};

export const orderState = {
	approved: 0,
	inPreparation: 1,
	shipping: 2,
	completed: 3,
	canceled: 4,
};

export const orderStates = [
	{ id: 0, name: i18n.t('orderStates.approved') },
	// { id: 1, name: i18n.t('orderStates.in-progress') },
	// { id: 2, name: i18n.t('orderStates.shipping') },
	{ id: 3, name: i18n.t('orderStates.completed') },
	{ id: 4, name: i18n.t('orderStates.canceled') },
];

export const upSellingEntryType = {
	yesNo: 0,
	count: 1,
	multiChoice: 2,
	multiple: 3,
	yesNoMultiChoice: 4,
};

export const upSellingEntryTypes = [
	{ id: 0, name: i18n.t('upSellingEntryTypes.yes-no') },
	{ id: 1, name: i18n.t('upSellingEntryTypes.count') },
	{ id: 2, name: i18n.t('upSellingEntryTypes.multi-choice') },
	{ id: 3, name: i18n.t('upSellingEntryTypes.multiple') },
	{ id: 4, name: i18n.t('upSellingEntryTypes.yes-no-multi-choice') },
];

export const loyaltyPointState = {
	spent: 1,
	earned: 2,
}

export const weekDays = [
	{ id: 1, name: i18n.t('week-days.saturday') },
	{ id: 2, name: i18n.t('week-days.sunday') },
	{ id: 3, name: i18n.t('week-days.monday') },
	{ id: 4, name: i18n.t('week-days.tuesday') },
	{ id: 5, name: i18n.t('week-days.wednesday') },
	{ id: 6, name: i18n.t('week-days.thursday') },
	{ id: 7, name: i18n.t('week-days.friday') },
]

export const sellerTypes = [
	{ id: 0, name: i18n.t('sellersType.publishing-house') },
	{ id: 1, name: i18n.t('sellersType.seller') }
];