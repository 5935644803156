<template>
	<div>
		<!-- settings dialog -->
		<v-dialog
			v-model="settingsDialog"
			max-width="350"
			content-class="rounded-medium"
		>
			<v-card>
				<v-card-title class="justify-center text-uppercase text-h6">
					{{$t('LoyaltyPoints.points-settings')}}
				</v-card-title>
				<v-card-text class="pb-2">
					<v-row dense>
						<!-- price pre loyalty point -->
						<v-col cols="12">
							<v-text-field
								v-model="loyaltyPointPrice"
								:label="$t('inputs.loyalty-point-price')"
								dense
								hide-details
								outlined
								class="rounded-medium"
							></v-text-field>
						</v-col>

						<!-- expiry duration -->
						<v-col cols="12">
							<v-text-field
								v-model="expiryDuration"
								:label="$t('inputs.expiry-duration')"
								dense
								hide-details
								outlined
								class="rounded-medium"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn color="primary" class="rounded-medium">
						{{$t('save')}}
					</v-btn>
					<v-btn class="rounded-medium" @click="settingsDialog = false">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-form ref="form" @submit.prevent="filter">
			<v-card class="rounded-medium mb-3">
				<v-card-text class="pa-2">
					<v-row dense class="align-center">
						<!-- change settings -->
						<v-col lg="3" md="4" cols="12" class="text-sm-start text-center">
							<v-btn
								v-if="false"
								dark
								color="darkGreen"
								class="rounded-small"
								@click="settingsDialog = true"
							>
								<v-icon class="me-1">mdi-star-cog</v-icon>
								{{$t('LoyaltyPoints.change-settings')}}
							</v-btn>

							<!-- category -->
							<v-autocomplete
								v-model="userId"
								:items="users"
								:item-text="item => item.fullName + ` <${item.userName}>`"
								item-value="id"
								:label="$t('inputs.user')"
								hide-details
								outlined
								dense
								:disabled="filterLoading || usersLoading"
								:loading="usersLoading"
								class="rounded-medium me-sm-3 mb-sm-0 mb-5"
								:rules="rules.requiredAsNumber"
							></v-autocomplete>
						</v-col>
			
						<v-spacer/>
			
						<!-- search -->
						<v-col
							sm="auto"
							cols="12"
							class="d-flex flex-sm-row flex-column align-center text-md-start text-center"
						>
							<v-btn
								type="submit"
								color="primary"
								class="rounded-small"
								:disabled="usersLoading"
								:loading="filterLoading"
							>
								{{$t('search')}}
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-form>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="filterLoading ? [] : tableItems"
			:loading="filterLoading"
			:items-per-page="15"
			dense
			:no-data-text="!userId ? $t('LoyaltyPoints.please-select-user') : '$vuetify.noDataText'"
			class="elevation-3 rounded-medium"
		>
			<!-- points count -->
			<template v-slot:item.count="{ value }">
				{{getLoyaltyPointsValue(value)}}
			</template>
			
			<!-- user points -->
			<template v-slot:item.current="{ value }">
				{{getLoyaltyPointsValue(value)}}
			</template>
			
			<!-- order -->
			<template v-slot:item.order="{ value }">
				{{value || '-'}}
			</template>

			<!-- state -->
			<template v-slot:item.state="{ value }">
				<span v-if="value === loyaltyPointState.earned" class="success--text">
					<v-icon class="me-1" size="18" color="success">mdi-arrow-up</v-icon>
					{{$t('LoyaltyPoints.earned')}}
				</span>
				<span v-else-if="value === loyaltyPointState.spent" class="deep-orange--text">
					<v-icon class="me-1" size="18" color="deep-orange">mdi-arrow-down</v-icon>
					{{$t('LoyaltyPoints.spent')}}
				</span>
				<span v-else>-</span>
			</template>

			<!-- referral user -->
			<template v-slot:item.referralUserId="{ value }">
				{{value ? getUserById(value).fullName : '-'}}
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<tooltip :text="$t('tooltip.preview-order-details')">
					<div class="d-flex justify-center">
						<v-btn
							color="deep-purple darken-2"
							dark
							small
							class="me-1 px-1 flex-shrink-1"
							min-width="20px"
							:to="{ name: 'order', params: { id: item.order } }"
						><v-icon>mdi-eye</v-icon></v-btn>
					</div>
				</tooltip>
			</template>
		</v-data-table>
		
		<!-- footer -->
		<v-footer app inset color="white" class="mx-4 mb-2 py-2 rounded-medium" elevation="4">
			<v-row dense class="text-subtitle-2 text-center justify-space-around ma-0">
				<!-- points-balance -->
				<v-col md="auto" sm="4" cols="12">
					{{$t('LoyaltyPoints.points-balance')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{getLoyaltyPointsValue(loyaltyPointsSummery.pointsBalance) || '-'}}
					</span>
				</v-col>
				
				<!-- expire-in -->
				<v-col md="auto" sm="4" cols="12">
					{{$t('LoyaltyPoints.expire-in')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{
							loyaltyPointsSummery.expireIn
								? moment(loyaltyPointsSummery.expireIn).format('YYYY-MM-DD')
								: '-'
						}}
					</span>
				</v-col>
				
				<!-- total-earned-points -->
				<v-col md="auto" sm="4" cols="12">
					{{$t('LoyaltyPoints.total-earned-points')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{getLoyaltyPointsValue(loyaltyPointsSummery.totalEarnedPoints) || '-'}}
					</span>
				</v-col>
				
				<!-- total-spent-points -->
				<v-col md="auto" sm="6" cols="12">
					{{$t('LoyaltyPoints.total-spent-points')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{Math.abs(getLoyaltyPointsValue(loyaltyPointsSummery.totalSpentPoints)) || '-'}}
					</span>
				</v-col>
			</v-row>
		</v-footer>
	</div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import { loyaltyPointState } from '@/helpers/enums'
import rules from '@/helpers/validation rules'
import { getLoyaltyPointsValue } from '@/helpers/functions'
import { useReferralMarketing } from '@/configs/global.config'

export default {
	name: 'LoyaltyPoints',

	data() {
		return {
			usersLoading: false,
			settingsDialog: false,
			rules,

			// settings
			loyaltyPointState,
			loyaltyPointPrice: null,
			expiryDuration: null,

			// filter
			filterLoading: null,
			userId: null,
			isFiltered: false,
		}
	},

	computed: {
		...mapState({
			users: state => state.users.users,
			loyaltyPoints: state => state.loyaltyPoints.loyaltyPoints,
			loyaltyPointsSummery: state => state.loyaltyPoints.loyaltyPointsSummery,
		}),

		...mapGetters({
			getUserById: 'users/getUserById'
		}),

		headers() {
			const headers = [
				{
					text: this.$t('headers.points-count'),
					align: 'center',
					value: 'count',
					class: 'primary--text',
					cellClass: 'primary--text',
				},
				{
					text: this.$t('headers.state'),
					align: 'center',
					value: 'state',
				},
				{
					text: this.$t('headers.user-points'),
					align: 'center',
					value: 'current',
				},
				{
					text: this.$t('headers.order'),
					align: 'center',
					value: 'order',
				},
				{
					text: this.$t('headers.date'),
					align: 'center',
					value: 'date',
				},
				{
					text: this.$t('headers.notes'),
					value: 'message',
					width: 320
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					width: 40,
					sortable: false
				},
			];

			if (useReferralMarketing) {
				headers.splice(headers.length - 2, 0, {
					text: this.$t('headers.referral-user'),
					value: 'referralUserId',
				})
			}
			return headers;
		},

		tableItems() {
			return this.loyaltyPoints.map(c => ({
				...c,
				count: Math.abs(c.point),
				state: c.point && (c.point > 0 ? loyaltyPointState.earned : loyaltyPointState.spent),
				date: moment(`${c.createdAt}Z`).format('YYYY-MM-DD hh:mm a')
			}))
		},
	},

	methods: {
		filter(isQueryLoad = false) {
			if (isQueryLoad || this.$refs.form.validate()) {
				this.isFiltered = true;
				this.filterLoading = true;
				Promise.all([
					this.$store.dispatch('loyaltyPoints/fetchUserPoints', { userId: this.userId }),
					this.$store.dispatch('loyaltyPoints/fetchUserPointsCount', { userId: this.userId })
				]).finally(() => {
					this.filterLoading = false;
				})

				if (+this.$route.query.userId !== this.userId) {
					this.$router.replace({ name: this.$route.name, query: { userId: this.userId } })
				}
			}
		},

		// helpers
		moment,
		getLoyaltyPointsValue
	},

	created() {
		if (!this.users.length) {
			this.usersLoading = true;
			this.$store.dispatch('users/fetchAll').finally(() => {
				this.usersLoading = false;
			})
		}
		if (this.$route.query.userId) {
			this.userId = +this.$route.query.userId;
			this.filter(true);
		}
	},

	beforeDestroy() {
		this.$store.dispatch('loyaltyPoints/reset');
	}
}
</script>

<style lang="scss" scoped>
.message-area {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20vh;
}
</style>