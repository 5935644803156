<template>
	<div
		ref="editorWrapper"
		:style="`--ck-content-height: ${height}px`"
	>
		<!-- <VFileManger
			v-model="fileManager"
			@image-selected="insertCallback"
		/> -->

		<!-- insert image dialog -->
		<v-dialog
			v-model="insertImageDialog.dialog"
			max-width="350"
			content-class="rounded-medium"
		>
			<v-form
				ref="insertImageForm"
				@submit.prevent="
					editor.execute('insertImage', {
						source: [{
							src: insertImageDialog.imageUrl,
							width: insertImageDialog.width,
							height: insertImageDialog.height
						}]
					});
					editor.execute('imageStyle', { value: 'inline' });
					insertImageDialog.dialog = false;
				"
			>
				<v-card>
					<v-card-title class="justify-center text-uppercase text-h6">
						Insert image
					</v-card-title>
					<v-card-text class="pb-1">
						<v-row dense justify="center" align="center">
							<!-- imageUrl -->
							<v-col
								v-if="insertImageDialog.imageUrl"
								cols="12"
								class="d-flex justify-center align-center"
							>
								<v-img
									:src="insertImageDialog.imageUrl"
									lazy-src="@/assets/blurred.png"
									max-width="200"
									width="200"
									class="rounded-medium mx-auto mb-3"
								/>
								<v-tooltip top>
									<template v-slot:activator="{ attr, on }">
										<v-btn
											absolute
											rounded
											min-width="0"
											width="35"
											height="35"
											class="px-0"
											color="primary"
											v-bind="attr"
											v-on="on"
											@click="activateFileManager"
										>
											<v-icon color="white">mdi-rotate-3d-variant</v-icon>
										</v-btn>
									</template>
									{{$t('tooltip.replace-the-image')}}
								</v-tooltip>
							</v-col>
							
							<!-- width -->
							<v-col sm="5" cols="12">
								<v-text-field
									v-model="insertImageDialog.width"
									label="width"
									type="number"
									hide-spin-buttons
									dense
									outlined
									hide-details
									class="rounded-medium"
									@input="
										insertImageDialog.retainRatio
											? insertImageDialog.height =
												fixNumber(insertImageDialog.width / insertImageDialog.ratio, 3)
											: null
									"
								/>
							</v-col>
							
							<!-- retain ratio -->
							<v-col sm="1" cols="12" class="d-flex justify-center pa-sm-0">
								<v-btn
									min-width="0"
									width="30"
									height="30"
									icon
									:color="insertImageDialog.retainRatio ? 'primary' : null"
									@click="insertImageDialog.retainRatio = !insertImageDialog.retainRatio"
								>
									<v-icon>
										{{insertImageDialog.retainRatio ? 'mdi-link' : 'mdi-link-off'}}
									</v-icon>
								</v-btn>
							</v-col>

							<!-- height -->
							<v-col sm="5" cols="12">
								<v-text-field
									v-model="insertImageDialog.height"
									label="height"
									type="number"
									hide-spin-buttons
									dense
									outlined
									hide-details
									class="rounded-medium"
									@input="
										insertImageDialog.retainRatio
											? insertImageDialog.width =
												fixNumber(insertImageDialog.height * insertImageDialog.ratio, 3)
											: null
									"
								/>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
						>{{$t('confirm')}}</v-btn>
						<v-btn
							class="rounded-small"
							@click="insertImageDialog.dialog = false"
						>{{$t('cancel')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- preview dialog -->
		<v-dialog v-model="previewDialog" max-width="900">
			<v-card>
				<v-card-text class="pa-4">
					<div v-html="localValue" class="ck-content"></div>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-medium"
						@click="previewDialog = false"
					>
						{{$t('close')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<editor 
			v-model="localValue"
			:editor="editorType"
			:config="editorConfig"
			:disabled="disabled"
			:class="{ 'validate-color': error }"
			@ready="$emit('ready', $event)"
			@focus="$emit('focus', $event)"
			@blur="$emit('blur', $event)"
			@destroy="$emit('destroy', $event)"
		/>
		<v-progress-linear
			v-if="loading"
			indeterminate
			class="editor-progress"
		/>
	</div>
</template>

<script>
// import VFileManger from '@/components/VLegacyFileManger.vue'
// import VFileManger from '@/components/file-manager/VFileManagerDialog.vue'

import CKEditor from '@ckeditor/ckeditor5-vue2';
// Markdown Title
import { InlineEditor, ClassicEditor, AccessibilityHelp, Alignment, Autoformat, AutoImage, AutoLink, Autosave, BalloonToolbar, ShowBlocks, BlockQuote, BlockToolbar, Bold, Code, CodeBlock, Essentials, FindAndReplace, FontBackgroundColor, FontColor, FontFamily, FontSize, GeneralHtmlSupport, Heading, Highlight, HorizontalLine, HtmlComment, HtmlEmbed, ImageBlock, ImageCaption, ImageInline, ImageInsertViaUrl, ImageUpload, Image, ImageResize, ImageStyle, ImageTextAlternative, ImageToolbar, Base64UploadAdapter, Indent, IndentBlock, Italic, Link, LinkImage, List, ListProperties, MediaEmbed, PageBreak, Paragraph, PasteFromMarkdownExperimental, PasteFromOffice, Clipboard, RemoveFormat, SelectAll, SpecialCharacters, SpecialCharactersArrows, SpecialCharactersCurrency, SpecialCharactersEssentials, SpecialCharactersLatin, SpecialCharactersMathematical, SpecialCharactersText, Strikethrough, Style, Subscript, Superscript, Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar, TextPartLanguage, TextTransformation, TodoList, Underline, Undo, SourceEditing, icons, ButtonView, Plugin } from 'ckeditor5';

import 'ckeditor5/dist/ckeditor5.css';
import { fixNumber } from '@/helpers/functions';

export default {
	name: 'CKEditor',
	props: {
		height: { type: Number, default: 450 },
		'mini-tools': { type: Boolean, default: false },
		'hide-menubar': { type: Boolean, default: false },
		'floating-toolbar': { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		'use-template-builder': { type: Boolean, default: false },
		error: { type: Boolean, default: false },
		value: { type: String, default: '' },
	},
	
	components: { editor: CKEditor.component },

	data(vm) {
		return {
			useLegacy: false,
			fileManager: false,
			editor: {},

			insertImageDialog: {
				dialog: false,
				imageUrl: null,
				width: null,
				height: null,
				ratio: null,
				retainRatio: true,
			},

			previewDialog: false,

			editorType: this.floatingToolbar ? InlineEditor : ClassicEditor,
			editorData: '<p>Hello from CKEditor 5 in Vue 2!</p>',
			editorConfig: {
				ui: {
					viewportOffset: { top: 58 }
				},
				language: {
					ui: 'en',
					content: 'en'
				},
				toolbar: {
					items: !this.miniTools
						? [
							'undo', 'redo', '|',
							// 'heading', 'style', '|',
							'heading', '|',
							'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
							'bold', 'italic', 'underline', 'link', '|',
							'direction:ltr', 'direction:rtl', 'alignment', '|',
							'bulletedList', 'numberedList', 'todoList', 'indent', 'outdent', '|',
							'insertImageFormFileManger', 'insertImage', 'insertTable', '|',
							'templateBuilder', '|',
							'blockQuote', 'codeBlock', '|',
							'preview', 'showBlocks', 'sourceEditing'
						]
						: [
							'bold', 'italic', 'underline', 'link', '|',
							'alignment', '|',
							'bulletedList', 'numberedList',
							'fontColor', 'fontBackgroundColor'
						],
					shouldNotGroupWhenFull: true
				},
				balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
				blockToolbar: {
					items: !this.floatingToolbar
						? [
							'bold', 'italic', 'underline', 'link', '|',
							'alignment', '|',
							'fontColor', 'fontBackgroundColor'
							/* 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
							'bold', 'italic', '|',
							'link', 'insertTable', '|',
							'bulletedList', 'numberedList', 'indent', 'outdent' */
						]
						: [],
					shouldNotGroupWhenFull: false
				},
				fontFamily: {
					supportAllValues: true
				},
				fontSize: {
					options: [10, 12, 14, 'default', 18, 20, 22],
					supportAllValues: true
				},
				heading: {
					options: [
						{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
						{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
						{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
						{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
						{ model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
						{ model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
						{ model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
					]
				},
				htmlSupport: {
					allow: [{ name: /^.*$/, styles: true, attributes: true, classes: true }]
				},
				image: {
					insert: {
						// This is the default configuration, you do not need to provide
						// this configuration key if the list content and order reflects your needs.
						integrations: ['upload', 'assetManager', 'url'],
						type: 'auto'
					},
					toolbar: [
						'toggleImageCaption', 'imageTextAlternative', 'linkImage', '|',
						'imageStyle:block', 'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|',
						'resizeImage'
					]
				},
				link: {
					addTargetToExternalLinks: true,
					defaultProtocol: 'https://',
					decorators: {
						toggleDownloadable: {
							mode: 'manual',
							label: 'Downloadable',
							attributes: {
								download: 'file'
							}
						}
					}
				},
				list: {
					properties: { styles: true, startIndex: true, reversed: true }
				},
				menuBar: { isVisible: !this.hideMenubar },
				placeholder: 'Type or paste your content here!',
				style: {
					definitions: [
						{ name: 'Article category', element: 'h3', classes: ['category'] },
						{ name: 'Title', element: 'h2', classes: ['document-title'] },
						{ name: 'Subtitle', element: 'h3', classes: ['document-subtitle'] },
						{ name: 'Info box', element: 'p', classes: ['info-box'] },
						{ name: 'Side quote', element: 'blockquote', classes: ['side-quote'] },
						{ name: 'Marker', element: 'span', classes: ['marker'] },
						{ name: 'Spoiler', element: 'span', classes: ['spoiler'] },
						{ name: 'Code (dark)', element: 'pre', classes: ['fancy-code', 'fancy-code-dark'] },
						{ name: 'Code (bright)', element: 'pre', classes: ['fancy-code', 'fancy-code-bright'] }
					]
				},
				table: {
					contentToolbar: ['toggleTableCaption', 'tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
				},
				plugins: [
					AccessibilityHelp, Alignment, Autoformat, AutoImage, AutoLink, Autosave, BalloonToolbar, ShowBlocks, BlockQuote, BlockToolbar, Bold, Code, CodeBlock, Essentials, FindAndReplace, FontBackgroundColor, FontColor, FontFamily, FontSize, GeneralHtmlSupport, Heading, Highlight, HorizontalLine, HtmlComment, HtmlEmbed, ImageBlock, ImageCaption, ImageInline, ImageInsertViaUrl, ImageUpload, Image, ImageResize, ImageStyle, ImageTextAlternative, ImageToolbar, Base64UploadAdapter, Indent, IndentBlock, Italic, Link, LinkImage, List, ListProperties, MediaEmbed, PageBreak, Paragraph, PasteFromMarkdownExperimental, PasteFromOffice, Clipboard, RemoveFormat, SelectAll, SpecialCharacters, SpecialCharactersArrows, SpecialCharactersCurrency, SpecialCharactersEssentials, SpecialCharactersLatin, SpecialCharactersMathematical, SpecialCharactersText, Strikethrough, Style, Subscript, Superscript, Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar, TextPartLanguage, TextTransformation, TodoList, Underline, Undo, SourceEditing,

					class Inits extends Plugin {
						init() {
							const editor = this.editor;
							vm.editor = editor;
							
							this.editor.editing.view.document.on('keypress', (data, evt) => {
								vm.$emit('keypress', evt.domEvent);
							});
							this.editor.editing.view.document.on('keyup', (data, evt) => {
								vm.$emit('keyup', evt.domEvent);
							});
							this.editor.editing.view.document.on('keydown', (data, evt) => {
								vm.$emit('keydown', evt.domEvent);
							});
						}
					},
					class Preview extends Plugin {
						init() {
							const editor = this.editor;
							editor.ui.componentFactory.add('preview', locale => {
								const button = new ButtonView(locale);
								
								button.set({
									withText: false,
									icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" /></svg>',
									tooltip: 'Preview'
								});
								editor.on('change:isReadOnly', (e, name, value) => {
									button.isEnabled = !value;
								})
					
								// Define the button behavior on press.
								button.on('execute', () => {
									vm.previewDialog = true;
								});
					
								return button;
							})
						}
					},

					class InsertImageFormFileMangerPlugin extends Plugin {
						init() {
							const editor = this.editor;

							editor.ui.componentFactory.add('insertImageFormFileManger', locale => {
								const button = new ButtonView(locale);
								
								button.set({
									label: 'Insert image from file manager',
									withText: false,
									icon: icons.imageAssetManager,
									tooltip: 'insert image from file manager'
								});
								editor.on('change:isReadOnly', (e, name, value) => {
									button.isEnabled = !value;
								})
					
								// Define the button behavior on press.
								button.on('execute', () => {
									vm.insertImageDialog.dialog = true;
									vm.$nextTick(() => {
										vm.activateFileManager();
									})
								});
					
								return button;
							});

							if (vm.useTemplateBuilder) {
								editor.ui.componentFactory.add('templateBuilder', locale => {
									const button = new ButtonView(locale);
									button.set({
										label: 'template builder',
										withText: false,
										icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22,22H10V20H22V22M2,22V20H9V22H2M18,18V10H22V18H18M18,3H22V9H18V3M2,18V3H16V18H2M9,14.56A3,3 0 0,0 12,11.56C12,9.56 9,6.19 9,6.19C9,6.19 6,9.56 6,11.56A3,3 0 0,0 9,14.56Z" /></svg>',
										tooltip: 'Template builder'
									});
									editor.on('change:isReadOnly', (e, name, value) => {
										button.isEnabled = !value;
									})
						
									// Define the button behavior on press.
									button.on('execute', () => {
										vm.$eventBus.$emit('template-builder', vm.value, (codes, htmlCss) => {
											vm.localValue = codes;
										})
									});
						
									return button;
								});
							}
						}
					},

					class Direction extends Plugin {
						init() {
							const editor = this.editor;

							const changeDir = (dir) => {
								const editorContent =
									vm.$refs.editorWrapper.querySelector('.ck.ck-content.ck-editor__editable');
								editorContent.dir = dir;
							}
							const wrapContentDir = (dir, content) => {
								let div = document.createElement('div');
								div.innerHTML = content;
								div = div.firstChild;
								if (dir === 'rtl') {
									if ('dirWrapper' in div.dataset) return;
									return `<div data-dir-wrapper dir="rtl">${content}</div>`
								} else if (dir === 'ltr') {
									if ('dirWrapper' in div.dataset) content = div.innerHTML;
									return content;
								}
							}

							const buttonRTL = new ButtonView();
							const buttonLTR = new ButtonView();
							const isRTL = editor.ui.view.locale.contentLanguage === 'ar';
							
							editor.ui.componentFactory.add('direction:rtl', () => {
								buttonRTL.isOn = isRTL;
								buttonRTL.set({
									withText: false,
									icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8,17V14L4,18L8,22V19H20V17M10,10V15H12V4H14V15H16V4H18V2H10A4,4 0 0,0 6,6A4,4 0 0,0 10,10Z" /></svg>',
									tooltip: 'Right-to-Left'
								});
								editor.on('change:isReadOnly', (e, name, value) => {
									buttonRTL.isEnabled = !value;
								})
								buttonRTL.on('execute', () => {
									changeDir('rtl');
									vm.localValue = wrapContentDir('rtl', vm.localValue);
									buttonRTL.isOn = true;
									buttonLTR.isOn = false;
								});
								return buttonRTL;
							});
							editor.ui.componentFactory.add('direction:ltr', () => {
								buttonLTR.isOn = !isRTL;
								buttonLTR.set({
									withText: false,
									icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21,18L17,14V17H5V19H17V22M9,10V15H11V4H13V15H15V4H17V2H9A4,4 0 0,0 5,6A4,4 0 0,0 9,10Z" /></svg>',
									tooltip: 'Left-to-Right'
								});
								editor.on('change:isReadOnly', (e, name, value) => {
									buttonLTR.isEnabled = !value;
								})
								buttonLTR.on('execute', () => {
									changeDir('ltr');
									vm.localValue = wrapContentDir('ltr', vm.localValue);
									buttonRTL.isOn = false;
									buttonLTR.isOn = true;
								});
								return buttonLTR;
							});
						}
					}
				],
				// translations: [coreTranslations]
			}
		}
	},

	watch: {
		'insertImageDialog.dialog'(val) {
			if (!val) {
				this.$refs.insertImageForm.reset();
				this.insertImageDialog.imageUrl = null;
				this.insertImageDialog.ratio = null;
				this.insertImageDialog.retainRatio = true;
			}
		}
	},

	computed: {
		localValue: {
			get() { return this.value },
			set(val) { this.$emit('input', val) }
		}
	},

	methods: {
		activateFileManager() {
			this.$eventBus.$emit(
				'file-manager', { images: true }, this.insertCallback, () => {
					if (!this.insertImageDialog.imageUrl) {
						this.insertImageDialog.dialog = false;
					}
				}
			);
		},
		insertCallback(src) {
			this.insertImageDialog.imageUrl = src;

			// getting width and height of selected image
			const img = new window.Image();
			img.onload = () => {
				this.insertImageDialog.width = img.naturalWidth;
				this.insertImageDialog.height = img.naturalHeight;
				this.insertImageDialog.ratio = fixNumber(img.naturalWidth / img.naturalHeight, 3);
			};
			img.src = src;
		},
		// helpers
		fixNumber
	}
}
</script>

<style lang="scss">
// @import 'ckeditor5/ckeditor5.css';
:root {
    --ck-input-width: 0;
	--ck-border-radius: 9px;
	--ck-color-focus-border: #084566;
	// --ck-color-base-foreground: #084566;
	
	--ck-color-shadow-drop: none;
    --ck-color-shadow-inner: none;
	--ck-color-button-on-active-shadow: none;
	--ck-color-button-action-active-shadow: none;
	--ck-focus-outer-shadow: none;

	// --ck-color-button-default-background: #084566;
	// --ck-color-button-default-hover-background: #084566cc;
	// --ck-color-button-default-active-background: #084566ff;

	--ck-color-button-on-color: #08547e;
	
    --ck-color-button-action-background: #084566;
    --ck-color-button-action-hover-background: #084566ee;
    --ck-color-button-action-active-background: #084566ff;
	--ck-color-button-action-text: white;
    --ck-color-button-action-active-shadow: none;

	--ck-content-height: 450px;
}

.ck {
	.ck *:focus-within {
		outline: none;
	}
	&.ck-content {
		max-height: var(--ck-content-height);
		min-height: var(--ck-content-height);
	}
	.ck-input {
		width: 100%;
	}
	.ck-dialog-overlay {
		z-index: 10;
	}
	.ck-powered-by {
		display: none;
	}
	.ck-menu-bar {
		border-top-left-radius: var(--ck-border-radius);
		border-top-right-radius: var(--ck-border-radius);
	}
	// .ck-block-toolbar-button { transform: translateX(20px); }

	&.ck-editor {
		margin: 0 8px;
	}

	.validate-color + &.ck-editor {
		outline: 1px solid red;
		border-radius: var(--ck-border-radius);
	}

	&.ck-editor__editable_inline {
		background: white;
	}
}
</style>