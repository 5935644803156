<template>
	<div>
		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			max-width="460"
			content-class="rounded-medium"
			:persistent="updateStateLoading"
		>
			<v-card>
				<v-card-title class="justify-center text-uppercase">
					{{$t('Orders.change-order-state')}} 
				</v-card-title>
				<v-card-text class="pb-2 text-body-1">
					{{$t('are-you-sure-you-want-to')}} 
					<span v-html="$t('Orders.change-state-to-$', {
						state:
							`<span class='${getStatusColor(orderState, true)}'>
								${orderStates.find(c => c.id === orderState)?.name}
							</span>`
					})"></span>
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn
						color="primary"
						class="rounded-medium"
						:loading="updateStateLoading"
						@click="changeState"
					>{{$t('confirm')}}</v-btn>
					<v-btn
						class="rounded-medium"
						:disabled="updateStateLoading"
						@click="orderState = originalOrderState; confirmDialog = false"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- description -->
		<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('Order.user-info')}}</div>
		<v-row dense>
			<!-- name -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.name')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-account"
					:value="name"
					:loading="loading"
				/>
			</v-col>
			
			<!-- address -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.address')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-map-marker-outline"
					:value="address"
					:loading="loading"
				/>
			</v-col>
			
			<!-- phone -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.phone')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-phone"
					:value="phone"
					:loading="loading"
				/>
			</v-col>
			
			<!-- email -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.email')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-email-outline"
					:value="email"
					:loading="loading"
				/>
			</v-col>

			<!-- date -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.date')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-calendar"
					:value="date ? moment(date).format('DD-MM-YYYY') : null"
					:loading="loading"
				/>
			</v-col>
		</v-row>

		<div class="text-body-1 font-weight-bold text-capitalize mt-2 mb-3">{{$t('Order.payment-info')}}</div>
		<v-row dense>
			<!-- total -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.total')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-cash"
					:value="total"
					:loading="loading"
				/>
			</v-col>
			
			<!-- payment type -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.payment-type')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-credit-card-outline"
					:value="paymentType"
					:loading="loading"
				/>
			</v-col>

			<!-- order state -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-select
					v-model="orderState"
					:items="orderStates"
					item-text="name"
					item-value="id"
					:label="$t('Order.order-state')"
					outlined
					dense
					hide-details
					prepend-icon="mdi-cart"
					class="rounded-medium"
					:append-icon="orderState === 4 ? '' : 'mdi-chevron-down'"
					:loading="loading"
					:readonly="loading || orderState === 4"
					@change="confirmDialog = true"
				>
					<template v-slot:selection="{ item }">
						<span :class="getStatusColor(item.id, true)" class="text-uppercase">
							{{item.name}}
						</span>
					</template>
					<template v-slot:item="{ item }">
						<v-icon size="30" :color="getStatusColor(item.id)" class="me-1">
							mdi-circle-medium
						</v-icon>
						<span :class="getStatusColor(item.id, true)" class="text-capitalize">
							{{item.name}}
						</span>
					</template>
				</v-select>
			</v-col>
		</v-row>

		<!-- cart -->
		<div class="text-body-1 font-weight-bold text-capitalize mt-2 mb-3">{{$t('Order.cart')}}</div>
		<v-data-table
			:headers="headers"
			:items="loading ? [] : products"
			:loading="loading"
			:items-per-page="10"
			dense
			class="elevation-2 rounded-medium transparent"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primaryImage ? item.primaryImage.url : require('@/assets/placeholder-image.png')"
					:height="45"
					:width="45"
					lazy-src="@/assets/blurred.png"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- categories -->
			<template v-slot:item.categoriesIds="{ item }">
				{{getCategories(item) | isAvailable}}
			</template>
		</v-data-table>
	</div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex';
import { orderState, orderStates } from '@/helpers/enums';

export default {
	name: 'Order',

	data: () => ({
		orderStates,

		confirmDialog: false,
		originalOrderState: null,
		updateStateLoading: false,
		
		name: null,
		address: null,
		phone: null,
		email: null,
		date: null,
		total: null,
		paymentType: null,
		orderState: null,
		products: [],

		loading: false,
	}),

	computed: {
		...mapState({
			categories: state => state.categories.categories,
		}),

		...mapGetters({
			getCategoryById: 'categories/getCategoryById',
		}),
		
		headers() {
			return [
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.category'), value: 'categoriesIds', align: 'center', sortable: false },
				{ text: this.$t('headers.quantity'), value: 'quantity', align: 'center' },
				{ text: this.$t('headers.price'), value: 'price', align: 'center' },
				{ text: this.$t('headers.total'), value: 'total', align: 'center' },
			]
		}
	},

	methods: {
		getCategories(item) {
			const names = [];
			item.categoriesIds.forEach(id => {
				const category = this.getCategoryById(id).name;
				names.push(category);
			});

			return names.join(', ')
		},

		getStatusColor(state, isText = false) {
			switch (state) {
			case orderState.approved : return `light-blue${isText ? '--text text--' : ' '}darken-1`;
			case orderState.inPreparation : return `deep-orange${isText ? '--text' : ''}`;
			case orderState.shipping : return `indigo${isText ? '--text text--' : ' '}darken-4`;
			case orderState.completed : return `success${isText ? '--text' : ''}`;
			case orderState.canceled : return `red${isText ? '--text text--' : ' '}lighten-1`;
			default : return `primary${isText ? '--text' : ''}`
			}
		},

		fetchOrder(isFirstLoad = false) {
			this.loading = true;
			return this.$store.dispatch('orders/fetchById', { id: this.$route.params.id })
				.then((data) => {
					this.name = data.fullName;
					this.address = data.address;
					this.phone = data.phone;
					this.email = data.email;
					this.date = data.createdAt;
					this.total = data.total;
					this.paymentType = data.paymentType;
					this.orderState = data.status;
					this.originalOrderState = data.status;
				
					this.products = data.cart.products.map(c => {
						const product = c.product;
						product.quantity = c.quantity;
						product.total = c.total;
						return product
					});
				}).finally(() => {
					if (!isFirstLoad) this.loading = false;
				})
		},

		changeState() {
			this.updateStateLoading = true;
			this.$store.dispatch('orders/changeState', {
				id: this.$route.params.id,
				status: this.orderState
			}).then(() => {
				this.confirmDialog = false;
				this.$eventBus.$emit(
					'show-snackbar',
					false,
					this.$t('$-is-updated-successfully', { name: this.$t('Orders.order-status') })
				);
				this.fetchOrder();
			}).finally(() => {
				this.updateStateLoading = false;
			})
		},

		moment,
	},

	created() {
		this.loading = true;
		Promise.all([
			this.categories.length === 0 ? this.$store.dispatch('categories/fetchAll') : null,
			this.fetchOrder(true)
		]).finally(() => {
			this.loading = false;
		})
	}
}
</script>

<style>

</style>