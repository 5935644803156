import axios from 'axios';

// const sanad = window.sanad;
const sanad = axios.create({
	baseURL: 'https://api.elasticemail.com/v4',
	headers: {
		common: {
			'X-ElasticEmail-ApiKey': '8A4CBC98F4FB0E2B29C7F61906E8F80F56D558226284E582CC7BDBD321D5F8FB5A2D9A2A5BBBDE5B5B9AC594567DBE11'
		}
	}
});

function getPreheader(str) {
	return str ? str + '&emsp;&zwnj;'.repeat(150) : '';
}

/* 
// abandoned cart 1
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com', Fields: { firstname: 'جورج' } }],
	Content: {
		TemplateName: 'abandoned_cart_2_hours_raw',
		Merge: { url: 'https://sanadagency.com/cart', year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'لا تفوتك منتجاتك المُختارة في السلة يا {firstname}!',
	},
	Options: {
		TimeOffset: 60 * 2, // 2 hours
	}
})

// abandoned cart 2
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'abandoned_cart_2_days_raw',
		Merge: { url: 'https://sanadagency.com/cart', year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'فرصتك الأخيرة لا تفوّتها!',
	},
	Options: {
		TimeOffset: 60 * 24 * 2, // 2 days
	}
})

// after purchase
// after purchase 1 
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'after_purchase_1_week_raw',
		Merge: { url: 'https://sanadagency.com', firstname: 'جورج', year: new Date().getFullYear(), preheader: getPreheader('منتجات قد تكون الإضافة الكاملة لمشروعك 🛍️') },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'اكتشف الجديد!',
	},
	Options: {
		TimeOffset: 60 * 24 * 7, // 1 week
	}
})

// after purchase 2
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'after_purchase_2_weeks_raw',
		Merge: { url: 'https://sanadagency.com', firstname: 'جورج', year: new Date().getFullYear(), preheader: getPreheader('تسوق الآن واكسب أكثر 💰') },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'استفد من النقاط برصيدك!',
	},
	Options: {
		TimeOffset: 60 * 24 * 7 * 2, // 2 week
	}
})

// after purchase 3 
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'after_purchase_3_weeks_raw',
		Merge: { url: 'https://sanadagency.com', year: new Date().getFullYear(), preheader: getPreheader('شاركنا رأيك 🌟') },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'نتمنى تكون تجربتك معنا مميزة!',
	},
	Options: {
		TimeOffset: 60 * 24 * 7 * 3, // 3 week
	}
})

// loyalty points
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'loyalty_points_1_month_remaining_raw',
		Merge: { url: 'https://sanadagency.com', year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'باقي شهر للاستمتاع بنقاط الكاش باك 🚨',
	}
})

sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'loyalty_points_1_week_remaining_raw',
		Merge: { url: 'https://sanadagency.com', year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'تذكير: نقاطك على وشك تختفي! ⏳️',
	}
})

// cashback notification
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com', Fields: { cashback: '10.500' } }],
	Content: {
		TemplateName: 'cashback_notification',
		Merge: { url: 'https://sanadagency.com', cashback: '10.500', user: '', year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'مبروك ربحت {cashback} د.ك برصيدك 🎉',
	}
})

// customized content
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'customized_content',
		Merge: { content: '', year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'إيميل مخصص',
	}
}) */

export function sendCustomizedEmail(emails, { subject, preheader, body }) {
	return sanad.post('/emails', {
		Recipients: (emails || []).map(c => ({ Email: c })),
		Content: {
			TemplateName: 'customized_content',
			Merge: {
				preheader: getPreheader(preheader),
				content: body,
				year: new Date().getFullYear(),
			},
			From: 'SANAD <noreply@sanadagency.com>',
			Subject: subject,
		}
	})
}